<template>
  <div class="profileDashboard" :class="'profileDashboard--' + dashboardType">
    <vue-scroll :ops="ops">
      <div v-if="dashboardType === 'flyer'" class="profileDashboard__wrapper" style="padding-right: 30px;">
        <div class="profileDashboard__left">
          <form
            class="form profileDashboard__form-info emptyImg js-profileForm whitePicker"
            :class="[{'editField showSubmit' : editing}, {'loading' : loadingForm}]"
            @submit.prevent="submitHandlerFlyer"
          >
            <div class="profileDashboard__section">
              <div class="profileDashboard__header">
                <div class="profileDashboard__title">Personal information</div>
                <div class="profileDashboard__change js-editForm" @click="editing = true">Change info</div>
                <button class="profileDashboard__button js-submitForm">
                  <span class="text">Save</span>
                </button>
              </div>
              <div class="profileDashboard__content">
                <div class="profileDashboard__upload">
                  <div class="profileDashboard__avatar js-avatar">
                    <label for="avatar">
                      <div class="profileDashboard__avatar-img js-avatar-img">
                        <img
                          v-if="uploader.files.length"
                          :src="uploader.files[0].url"
                          class="rounded-circle"
                          :class="{'edit' : editing}"/>
                        <div v-else>
                          <span class="profileDashboard__avatar-icon">
                            <SvgIcon name="photo-camera"/>
                          </span>
                          <span class="profileDashboard__avatar-caption">Upload photo</span>
                        </div>
                      </div>
                      <div v-if="uploader.files.length" class="profileDashboard__avatar-change">Change photo</div>
                    </label>
                    <div class="profileDashboard__avatar-crop" :class="{'showCrop' : uploader.files.length}" v-if="uploader.files.length">
                      <cropper
                        class="cropper"
                        :src="uploader.files[0].url"
                        :auto-zoom="true"
                        stencil-component="circle-stencil"
                        :stencil-props="{
                          handlers: {},
                          movable: false,
                          resizable: false,
                          aspectRatio: 1,
                        }"
                        :resize-image="{
                          adjustStencil: false
                        }"
                        :stencil-size="{
                          width: 110,
                          height: 110
                        }"
                        image-restriction="stencil"
                        @change="change"
                      />
                      <button class="profileDashboard__avatar-button" @click="saveEditImg" type="button">
                        <div class="text">Crop photo</div>
                      </button>
                    </div>
                    <div>
                      <file-upload
                        extensions="gif,jpg,jpeg,png,webp"
                        accept="image/png,image/gif,image/jpeg,image/webp"
                        name="avatar"
                        class="btn btn-primary"
                        post-action="/upload/post"
                        :drop="!uploader.edit"
                        v-model="uploader.files"
                        @input-filter="inputFilter"
                        @input-file="inputFile"
                        ref="upload">
                      </file-upload>
                    </div>
                  </div>
                </div>
                <div class="profileDashboard__personal-info">
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">First Name:</div>
                    <input
                      class="profileDashboard__personal-info-field"
                      type="text"
                      v-model="firstName"
                      placeholder="enter first name*"
                      :disabled="loadingForm"
                    />
                    <span
                      v-if="$v.firstName.$dirty && !$v.firstName.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Middle Name:</div>
                    <input
                      class="profileDashboard__personal-info-field"
                      type="text"
                      v-model="middleName"
                      placeholder="enter name"
                      :disabled="loadingForm"
                    />
                  </label>
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Last Name:</div>
                    <input
                      class="profileDashboard__personal-info-field"
                      type="text"
                      v-model="lastName"
                      placeholder="enter last name*"
                      :disabled="loadingForm"
                    />
                    <span
                      v-if="$v.lastName.$dirty && !$v.lastName.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Birth:</div>
<!--                    <a-date-picker-->
<!--                      placeholder="click to change"-->
<!--                      :showToday="false"-->
<!--                      :allowClear="false"-->
<!--                      v-model="dateOfBirth"-->
<!--                      :format="dateFormat"-->
<!--                      :disabled="loadingForm"-->
<!--                    />-->
                    <input
                      class="profileDashboard__personal-info-field"
                      type="text"
                      v-mask="'##/##/####'"
                      v-model="dateOfBirth"
                      :placeholder="dateFormat"
                      :disabled="loadingForm"
                      :getCalendarContainer="getPickerContainer"
                    />
                    <span
                      v-if="new Date(dateOfBirth) == 'Invalid Date' && dateOfBirth.length === 10"
                      class="form__field-invalid form__field-invalid--right-side"
                      style="color: #45454f"
                    >
                      Invalid Date
                    </span>
                  </label>
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Email:</div>
                    <input
                      class="profileDashboard__personal-info-field"
                      type="text"
                      v-model="email"
                      placeholder="enter email*"
                      :disabled="loadingForm"
                    />
                    <span
                      v-if="$v.email.$dirty && !$v.email.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                    <span
                      v-if="$v.email.$dirty && !$v.email.email"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      Enter a valid Email
                    </span>
                  </label>
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Phone:</div>
                    <input
                      class="profileDashboard__personal-info-field phone"
                      type="text"
                      v-model="mobile"
                      placeholder="enter phone*"
                      :disabled="loadingForm"
                    />
                    <span
                      v-if="$v.mobile.$dirty && !$v.mobile.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Address:</div>
                    <textarea
                      class="profileDashboard__personal-info-field"
                      v-model="address"
                      placeholder="enter address*"
                      :disabled="loadingForm"
                    ></textarea>
                    <span
                      v-if="$v.address.$dirty && !$v.address.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="profileDashboard__section">
              <div class="profileDashboard__title">Additional Information</div>
              <div class="profileDashboard__content">
                <div class="profileDashboard__add-info">
                  <label class="profileDashboard__add-info-item">
                    <div class="profileDashboard__add-info-caption">Company name:</div>
                    <input
                      class="profileDashboard__add-info-field"
                      type="text"
                      v-model="companyName"
                      placeholder="enter company"
                      :disabled="loadingForm"
                    />
                  </label>
                  <label class="profileDashboard__add-info-item">
                    <div class="profileDashboard__add-info-caption">Company address:</div>
                    <input
                      class="profileDashboard__add-info-field"
                      type="text"
                      v-model="companyAddress"
                      placeholder="enter address"
                      :disabled="loadingForm"
                    />
                  </label>
                  <label class="profileDashboard__add-info-item">
                    <div class="profileDashboard__add-info-caption">Company email:</div>
                    <input
                      class="profileDashboard__add-info-field"
                      type="email"
                      v-model="companyEmail"
                      placeholder="enter email"
                      :disabled="loadingForm"
                    />
                    <span
                      v-if="$v.companyEmail.$dirty && !$v.companyEmail.email"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      Enter a valid Email
                    </span>
                  </label>
                  <label class="profileDashboard__add-info-item">
                    <div class="profileDashboard__add-info-caption">Company phone:</div>
                    <input
                      class="profileDashboard__add-info-field phone"
                      type="text"
                      v-model="companyPhone"
                      placeholder="enter phone"
                      :disabled="loadingForm"
                    />
                  </label>
                </div>
              </div>
              <div class="profileDashboard__footer">
                <div class="profileDashboard__change js-editForm" @click="editing = true">Change info</div>
                <button class="profileDashboard__button js-submitForm">
                  <span class="text">Save</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div v-else-if="dashboardType === 'provider'" class="profileDashboard__wrapper">
        <div class="profileDashboard__left">
          <form
            class="form profileDashboard__form-info emptyImg js-profileForm whitePicker"
            :class="[{'editField showSubmit' : editing}, {'loading' : loadingForm}]"
            id="providerProfileForm"
            @submit.prevent="submitHandlerProvider"
          >
            <div class="profileDashboard__section">
              <div class="profileDashboard__header">
                <div class="profileDashboard__title">Profile Information</div>
                <div class="profileDashboard__change js-editForm" @click="editing = true">Change info</div>
                <button class="profileDashboard__button js-submitForm">
                  <span class="text">Save</span>
                </button>
              </div>
              <div class="profileDashboard__content">
                <div class="profileDashboard__upload">
                  <div class="profileDashboard__avatar js-avatar">
                    <label for="avatar">
                      <div class="profileDashboard__avatar-img js-avatar-img">
                        <img
                          v-if="uploader.files.length"
                          :src="uploader.files[0].url"
                          class="rounded-circle"
                          :class="{'edit' : editing}"/>

                        <div v-else>
                          <span class="profileDashboard__avatar-icon">
                            <SvgIcon name="photo-camera"/>
                          </span>
                          <span class="profileDashboard__avatar-caption">Upload photo</span>
                        </div>
                      </div>
                      <div v-if="uploader.files.length" class="profileDashboard__avatar-change">Change photo</div>
                    </label>
                    <div class="profileDashboard__avatar-crop" :class="{'showCrop' : uploader.files.length}" v-if="uploader.files.length">
                      <cropper
                        class="cropper"
                        :src="uploader.files[0].url"
                        :auto-zoom="true"
                        stencil-component="circle-stencil"
                        :stencil-props="{
                          handlers: {},
                          movable: false,
                          resizable: false,
                          aspectRatio: 1,
                        }"
                        :resize-image="{
                          adjustStencil: false
                        }"
                        :stencil-size="{
                          width: 110,
                          height: 110
                        }"
                        image-restriction="stencil"
                        @change="change"
                      />
                      <button class="profileDashboard__avatar-button" @click="saveEditImg" type="button">
                        <div class="text">Crop photo</div>
                      </button>
                    </div>
                    <div>
                      <file-upload
                        extensions="gif,jpg,jpeg,png,webp"
                        accept="image/png,image/gif,image/jpeg,image/webp"
                        name="avatar"
                        class="btn btn-primary"
                        post-action="/upload/post"
                        :drop="!uploader.edit"
                        v-model="uploader.files"
                        @input-filter="inputFilter"
                        @input-file="inputFile"
                        ref="upload">
                      </file-upload>
                    </div>
                  </div>
                </div>
                <div class="profileDashboard__personal-info">
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">First Name:</div>
                    <input
                      class="profileDashboard__personal-info-field"
                      type="text"
                      v-model="firstName"
                      placeholder="enter name*"
                      :disabled="loadingForm"
                    />
                    <span
                      v-if="$v.firstName.$dirty && !$v.firstName.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Middle Name:</div>
                    <input
                      class="profileDashboard__personal-info-field"
                      type="text"
                      v-model="middleName"
                      :disabled="loadingForm"
                    />
                  </label>
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Last Name:</div>
                    <input
                      class="profileDashboard__personal-info-field"
                      type="text"
                      v-model="lastName"
                      placeholder="enter last name*"
                      :disabled="loadingForm"
                    />
                    <span
                      v-if="$v.lastName.$dirty && !$v.lastName.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Birth:</div>
                    <input
                      class="profileDashboard__personal-info-field"
                      type="text"
                      v-mask="'##/##/####'"
                      v-model="dateOfBirth"
                      :placeholder="dateFormat"
                      :disabled="loadingForm"
                      :getCalendarContainer="getPickerContainer"
                    />
                    <span
                      v-if="new Date(dateOfBirth) === 'Invalid Date' && dateOfBirth.length === 10"
                      class="form__field-invalid form__field-invalid--right-side"
                      style="color: #45454f"
                    >
                      Invalid Date
                    </span>
                  </label>

                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Email:</div>
                    <input
                      class="profileDashboard__personal-info-field"
                      type="text"
                      v-model="email"
                      placeholder="enter email*"
                      :disabled="loadingForm"
                    />
                    <span
                      v-if="$v.email.$dirty && !$v.email.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                    <span
                      v-if="$v.email.$dirty && !$v.email.email"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      Enter a valid Email
                    </span>
                  </label>

                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Phone:</div>
                    <input
                      class="profileDashboard__personal-info-field phone"
                      type="text"
                      v-model="mobile"
                      placeholder="enter phone*"
                      :disabled="loadingForm"
                    />
                    <span
                      v-if="$v.mobile.$dirty && !$v.mobile.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>

                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Company name:</div>
                    <input
                      class="profileDashboard__personal-info-field"
                      type="text"
                      v-model="companyName"
                      placeholder="enter name"
                      :disabled="loadingForm"
                    />
                  </label>

                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Work email:</div>
                    <input
                      class="profileDashboard__personal-info-field phone"
                      type="text"
                      v-model="companyEmail"
                      placeholder="enter email*"
                      :disabled="loadingForm"
                    />
                    <span
                      v-if="$v.companyEmail.$dirty && !$v.companyEmail.email"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      Enter a valid Email
                    </span>
                  </label>

                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Work phone:</div>
                    <input
                      class="profileDashboard__personal-info-field phone"
                      type="text"
                      v-model="companyPhone"
                      placeholder="enter phone*"
                      :disabled="loadingForm"
                    />
                  </label>
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Work address:</div>
                    <textarea
                      class="profileDashboard__personal-info-field"
                      v-model="companyAddress"
                      placeholder="enter address*"
                      :disabled="loadingForm"
                    ></textarea>
                  </label>
                </div>
              </div>
              <div class="profileDashboard__footer">
                <div class="profileDashboard__change js-editForm" @click="editing = true">Change info</div>
                <button class="profileDashboard__button js-submitForm">
                  <span class="text">Save</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div v-else>
        <div class="profileDashboard__left">
          <form
            class="form profileDashboard__form-info emptyImg js-profileForm whitePicker"
            :class="[{'editField showSubmit' : editing}, {'loading' : loadingForm}]"
            @submit.prevent="submitHandlerFlyer"
          >
            <div class="profileDashboard__section">
              <div class="profileDashboard__header">
                <div class="profileDashboard__title">Personal information</div>
                <div class="profileDashboard__change js-editForm" @click="editing = true">Change info</div>
                <button class="profileDashboard__button js-submitForm" type="submit">
                  <span class="text">Save</span>
                </button>
              </div>
              <div class="profileDashboard__content">
                <div class="profileDashboard__upload">
                  <div class="profileDashboard__avatar js-avatar">
                    <label for="avatar">
                      <div class="profileDashboard__avatar-img js-avatar-img">
                        <img
                          v-if="uploader.files.length"
                          :src="uploader.files[0].url"
                          class="rounded-circle"
                          :class="{'edit' : editing}"/>

                        <div v-else>
                          <span class="profileDashboard__avatar-icon">
                            <SvgIcon name="photo-camera"/>
                          </span>
                          <span class="profileDashboard__avatar-caption">Upload photo</span>
                        </div>
                      </div>
                      <div v-if="uploader.files.length" class="profileDashboard__avatar-change">Change photo</div>
                    </label>
                    <div class="profileDashboard__avatar-crop" :class="{'showCrop' : uploader.files.length}" v-if="uploader.files.length">
                      <cropper
                        class="cropper"
                        :src="uploader.files[0].url"
                        :auto-zoom="true"
                        stencil-component="circle-stencil"
                        :stencil-props="{
                          handlers: {},
                          movable: false,
                          resizable: false,
                          aspectRatio: 1,
                        }"
                        :resize-image="{
                          adjustStencil: false
                        }"
                        :stencil-size="{
                          width: 110,
                          height: 110
                        }"
                        image-restriction="stencil"
                        @change="change"
                      />
                      <button class="profileDashboard__avatar-button" @click="saveEditImg" type="button">
                        <div class="text">Crop photo</div>
                      </button>
                    </div>
                    <div>
                      <file-upload
                        extensions="gif,jpg,jpeg,png,webp"
                        accept="image/png,image/gif,image/jpeg,image/webp"
                        name="avatar"
                        class="btn btn-primary"
                        post-action="/upload/post"
                        :drop="!uploader.edit"
                        v-model="uploader.files"
                        @input-filter="inputFilter"
                        @input-file="inputFile"
                        ref="upload">
                      </file-upload>
                    </div>
                  </div>
                </div>
                <div class="profileDashboard__personal-info">
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Name:</div>
                    <input
                      class="profileDashboard__personal-info-field"
                      type="text"
                      v-model="firstName"
                      placeholder="enter name*"
                      :disabled="loadingForm"
                    />
                    <span
                      v-if="$v.firstName.$dirty && !$v.firstName.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Birth:</div>
                    <input
                      class="profileDashboard__personal-info-field"
                      type="text"
                      v-mask="'##/##/####'"
                      v-model="dateOfBirth"
                      :placeholder="dateFormat"
                      :disabled="loadingForm"
                      :getCalendarContainer="getPickerContainer"
                    />
                    <span
                      v-if="new Date(dateOfBirth) === 'Invalid Date' && dateOfBirth.length === 10"
                      class="form__field-invalid form__field-invalid--right-side"
                      style="color: #45454f"
                    >
                      Invalid Date
                    </span>
                  </label>
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Email:</div>
                    <input
                      class="profileDashboard__personal-info-field"
                      type="text"
                      v-model="email"
                      placeholder="enter email*"
                      :disabled="loadingForm"
                    />
                    <span
                      v-if="$v.email.$dirty && !$v.email.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                    <span
                      v-if="$v.email.$dirty && !$v.email.email"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      Enter a valid Email
                    </span>
                  </label>
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Phone:</div>
                    <input
                      class="profileDashboard__personal-info-field phone"
                      type="text"
                      v-model="mobile"
                      placeholder="enter phone*"
                      :disabled="loadingForm"
                    />
                    <span
                      v-if="$v.mobile.$dirty && !$v.mobile.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="profileDashboard__personal-info-item">
                    <div class="profileDashboard__personal-info-caption">Address:</div>
                    <textarea
                      class="profileDashboard__personal-info-field"
                      v-model="address"
                      placeholder="enter address*"
                      :disabled="loadingForm"
                    >
                      Lenina 45 Appartment 7 Rostov-on-Don. 344 067
                    </textarea>
                    <span
                      v-if="$v.address.$dirty && !$v.address.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                </div>
              </div>
              <div class="profileDashboard__footer">
                <div class="profileDashboard__change js-editForm" @click="editing = true">Change info</div>
                <button class="profileDashboard__button js-submitForm">
                  <span class="text">Save</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

    </vue-scroll>
    <DashboardPreloader/>
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import DashboardPreloader from '@/views/dashboard/common/DashboardPreloader/DashboardPreloader'
import FileUpload from 'vue-upload-component'
import {email, required} from 'vuelidate/lib/validators'
import moment from 'moment'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import '@/views/profile/Profile.styl'

function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  props: ['dashboardType'],
  name: 'Profile',
  data: () => ({
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    mobile: '',
    invalidDate: false,
    address: '',
    companyName: '',
    companyAddress: '',
    companyEmail: '',
    companyPhone: '',
    workEmail: '',
    workPhone: '',
    editing: false,
    loadingPhoto: false,
    imageUrl: '',
    avatar: '',
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    dateFormat: 'MM/DD/YYYY',
    loadingForm: false,
    addEmail: '',
    inviteEmail: '',
    editImg: {
      coordinates: {},
      url: '',
    },
    uploader: {
      files: [],
      edit: false,
    },
    uploaderDocument: {
      files: [],
      edit: false,
    },
  }),
  validations: {
    firstName: {required},
    lastName: {required},
    email: {
      email,
      required,
    },
    mobile: {required},
    workEmail: {email},
    address: {required},
    companyAddress: {required},
    companyEmail: {email},
    addEmail: {
      email,
      required,
    },
    inviteEmail: {
      email,
      required,
    },
  },
  async created () {
    await this.$store.dispatch('getProfile')

    this.updatePersonalInfo()

    const preloader = document.querySelector('.dashboardPreloader')
    preloader.classList.add('dashboardPreloader--hide')
  },
  mounted () {
    // this.tabs.addActiveForMouted()
    const insertContainer = document
      .querySelector('.profileDashboard__left')
      .querySelector('.profileDashboard__section')
    const docs = document.querySelector('.js-doc')

    if (docs && document.querySelector('html').classList.contains('d-mobile')) {
      insertContainer.appendChild(docs)
    }
  },
  computed: {
    personalInfo () {
      return this.$store.state.profile.personalInfo
    },
    additionalInfo () {
      return this.$store.state.profile.additionalInfo
    },
  },
  methods: {
    moment,
    toggleAddContent (event) {
      const item = event.currentTarget.closest('.js-acc-item').querySelector('.js-add-content')
      const status = this.$root.animations.slideToggle(item)
      status ? this.$refs.addTraveler.classList.add('open') : this.$refs.addTraveler.classList.remove('open')
    },
    getPickerContainer (trigger) {
      return document.querySelector('.whitePicker')
    },
    toggleContent (event) {
      const item = event.currentTarget.closest('.js-members-item').querySelector('.js-acc-content')
      this.$root.animations.slideToggle(item)
    },
    createImage (file) {
      const reader = new FileReader()
      const vm = this

      reader.onload = (e) => {
        vm.image = e.target.result
      }
      reader.readAsDataURL(file)
    },
    async submitHandlerFlyer () {
      if (
        this.$v.firstName.$invalid ||
        this.$v.lastName.$invalid ||
        this.$v.email.$invalid ||
        this.invalidDate
      ) {
        this.$v.$touch()
        return false
      }

      this.loadingForm = true

      const data = {
        firstName: this.firstName,
        middleName: this.middleName,
        lastName: this.lastName,
        dateOfBirth: this.dateOfBirth,
        mobile: this.mobile,
        email: this.email,
        address: this.address,
        companyName: this.companyName,
        companyAddress: this.companyAddress,
        companyEmail: this.companyEmail,
        companyPhone: this.companyPhone,
      }

      await this.$store.dispatch('updatePersonalInfo', data)
      this.editing = false
      this.loadingForm = false
      this.updatePersonalInfo()

      const message = {
        title: 'Profile Updated',
        text: 'Your profile has been updated successfully',
      }

      this.$modal.show('ThanksPopup', {message})
    },
    async submitHandlerProvider () {
      if (
        this.$v.firstName.$invalid ||
        this.$v.lastName.$invalid ||
        this.$v.email.$invalid ||
        this.$v.companyEmail.$invalid ||
        this.invalidDate
        // this.$v.mobile.$invalid
      ) {
        this.$v.$touch()
        return false
      }

      this.loadingForm = true

      const data = {
        firstName: this.firstName,
        middleName: this.middleName,
        lastName: this.lastName,
        dateOfBirth: this.dateOfBirth,
        email: this.email,
        mobile: this.mobile,
        companyName: this.companyName,
        companyEmail: this.companyEmail,
        companyPhone: this.companyPhone,
        companyAddress: this.companyAddress,
      }

      await this.$store.dispatch('updatePersonalInfoProvider', data)
      this.editing = false
      this.loadingForm = false
      this.updatePersonalInfo()

      const message = {
        title: 'Profile Updated',
        text: 'Your profile has been updated successfully',
      }

      this.$modal.show('ThanksPopup', {message})
    },
    handleChange (info) {
      if (info.file.status === 'uploading') {
        this.loadingPhoto = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl
          this.loadingPhoto = false
        })
      }
    },
    beforeUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!')
      }
      return isJpgOrPng && isLt2M
    },
    togglePayment (event) {
      const item = event.currentTarget.closest('.js-acc-item').querySelector('.js-acc-content-info')
      event.currentTarget.closest('.js-acc-item').classList.toggle('mutation')
      document.querySelectorAll('.js-acc-content-info').forEach((content) => {
        if (item !== content) {
          content.closest('.js-acc-item').classList.remove('mutation')
          this.$root.animations.slideUp(content)
        }
      })
      this.$root.animations.slideToggle(item)
    },
    editSave () {
      this.edit = false
      const oldFile = this.files[0]
      const binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
      const arr = new Uint8Array(binStr.length)
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }
      const file = new File([arr], oldFile.name, {type: oldFile.type})
      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true,
      })
    },
    inputFile (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          document.querySelector('.profileDashboard__avatar-crop').classList.add('showCrop')
          this.edit = true
          this.editing = true
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
    },
    inputFilter (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        const URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },
    change ({ coordinates, canvas }) {
      this.editImg.coordinates = coordinates
      this.editImg.url = canvas.toDataURL()
    },
    saveEditImg () {
      this.uploader.files[0].url = this.editImg.url
      document.querySelector('.profileDashboard__avatar-crop').classList.remove('showCrop')
    },
    inputFileDocument (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true
          this.editing = true
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
    },
    inputFilterDocument (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        const URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },
    updatePersonalInfo () {
      this.firstName = this.personalInfo.firstName
      this.middleName = this.personalInfo.middleName
      this.lastName = this.personalInfo.lastName
      this.dateOfBirth = moment(this.personalInfo.dateOfBirth).format('MM/DD/YYYY')
      this.email = this.personalInfo.email
      this.mobile = this.personalInfo.mobile
      this.address = this.personalInfo.address
      this.companyName = this.personalInfo.companyName
      this.companyAddress = this.personalInfo.companyAddress
      this.companyEmail = this.personalInfo.companyEmail
      this.companyPhone = this.personalInfo.companyPhone
    },
  },
  watch: {
    dateOfBirth () {
      if (new Date(this.dateOfBirth) === 'Invalid Date' && this.dateOfBirth.length === 10) {
        this.invalidDate = true;
      } else {
        this.invalidDate = false;
      }
    },
  },
  components: {
    SvgIcon,
    FileUpload,
    DashboardPreloader,
    Cropper,
  },
}
</script>
